<template>
  <vx-card class="mb-base">
    <campaign-message
      v-if="model"
      ref="campaignMessage"
      :key="campaignMessageKey"
      :message.sync="model.message"
      :has-interpolations.sync="model.hasInterpolations"
      :interpolations.sync="model.interpolations"
      :message-type="model.messageType"
      :caller-id-type="model.callerIdType"
      :sender-id="model.senderId"
      :sender-business-name="model.senderBusinessName"
      :is-quick="true"
      @validate="(val) => (campaignMessageIsValid = val)"
      @test="onTest"
      @change-to-transactional="changeToTransactional()">
      <template v-slot:before-msg>
        <campaign-information
          ref="campaignInfo"
          class="mb-6"
          :campaign-type="$enums.Campaign.Type.SMS"
          :operation="operation"
          :name.sync="model.name"
          :message-type.sync="model.messageType"
          :sender-id.sync="model.senderId"
          :sender-business-name.sync="model.senderBusinessName"
          :caller-id-type.sync="model.callerIdType"
          compact-design
          @validate="(val) => (campaignInfoIsValid = val)"/>

        <vs-divider class="my-base block md:hidden"/>
      </template>

      <template v-slot:after-msg>
        <vs-divider class="my-base block md:hidden"/>

        <v-select-server-phone-number
          ref="vSelectServerPhoneNumber"
          v-model="model.quickContacts"
          class="mt-6"
          :select-name="$tc('$Entities.Contact', 2)"
          :fetch-function="fetchAllContacts"
          :filter-params="filterParamsToFetchContacts"
          :use-default-search-filters="false"
          :search-filters="searchFiltersToFetchContacts"
          multiple
          taggable
          :select-on-tab="true"
          :placeholder="$tc('$SelectContacts.Placeholder')"
          :create-new-modal-entity="$tc('$Entities.Contact')"
          create-option-object-name="name"
          manage-route-name="contacts"
          :manage-text="$tc('$SelectContacts.ManageText')"
          :create-text="$t('CreateContactText')"
          :show-create="true"
          option-emphasis="phoneInternational">
          <template v-slot:create>
            <contact-list-create-or-edit
              :entity="$tc('$Entities.Contact')"
              :operation="$enums.Operation.CREATE"
              :all-contact-attributes="contactsAttributes"
              @saved="$refs.vSelectServerPhoneNumber.ItemCreatedOnModal()"
              @close="$refs.vSelectServerPhoneNumber.closeCreateModal()">
            </contact-list-create-or-edit>
          </template>
        </v-select-server-phone-number>

        <vs-divider class="my-base block md:hidden" />

        <campaign-settings
          ref="campaignSettings"
          class="mt-6"
          :campaign-type="$enums.Campaign.Type.SMS"
          :operation="operation"
          :delivery-type.sync="model.deliveryType"
          :local-start-date.sync="model.localStartDate"
          :time-zone.sync="model.timeZone"
          :is-quick="true"
          compact-design
          @validate="(val) => (campaignSettingsIsValid = val)"
          @start-immediately="(val) => campaignWillStartImmediately = val"
          @close-flat-picker="handleCloseFlatPicker">
        </campaign-settings>

        <base-form-footer-action-buttons
          @save="sendCampaign()"
          @cancel="confirmRestartCampaign">
          <template v-slot:cancel>
            {{ $t("$General.StartOver") }}
          </template>
          <template v-slot:save>
            {{ $t("$General.Send") }}
          </template>
        </base-form-footer-action-buttons>
      </template>
    </campaign-message>

    <package-checkout-modal :active.sync="showCheckout" />
    <sms-get-started-modal/>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { parsePhoneNumber } from 'libphonenumber-js';

import store from '@/store/store';
import SmsCampaignConstructor from '@/views/modules/campaigns/sms-campaign/sms-campaign.constructor';

// Components
import SMSCampaignListCreateOrEditMessage from '@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessage.vue';
import SMSCampaignGetStartedModal from '@/views/modules/campaigns/sms-campaign/_components/SMSCampaignGetStartedModal.vue';
import VSelectServerPhoneNumber from '@/views/modules/_components/v-select-server/VSelectServerPhoneNumber.vue';
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';
// Mixins
import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';
import quickCampaign from '@/views/modules/campaigns/common/mixins/quick-campaign';
import campaignScheduleRestrictions from '@/views/modules/campaigns/common/mixins/campaignScheduleRestrictions';

export default {
  name: 'SMSCampaignListQuickCreateOrEdit',
  i18n: {
    messages: {
      en: {
        CreateContactText: 'Create contact',
        $SelectContacts: {
          Placeholder: 'Select contacts or enter phone numbers',
          CreateNewTextLink: 'Create a new contact',
          ManageText: 'Manage contacts',
        },
        $ScheduleTimeInPastAlert: {
          Title: 'Schedule time is in the past',
          Text: 'You have scheduled your message to be sent in the past. Message will be sent immediately.',
        },
      },
    },
  },
  components: {
    BaseFormFooterActionButtons,
    campaignMessage: SMSCampaignListCreateOrEditMessage,
    smsGetStartedModal: SMSCampaignGetStartedModal,
    VSelectServerPhoneNumber,
  },
  mixins: [singleCreateOrEdit, quickCampaign, campaignScheduleRestrictions],
  props: {
    modelPayload: {
      type: Object,
      required: false,
      default() {
        return store.state.smsCampaign.payload
          ? store.state.smsCampaign.payload
          : null;
      },
    },
  },
  data() {
    return {
      campaignBaseRoute: '/sms-campaigns',
      addItemFunction: this.addSMSQuickCampaign,
      campaignWillStartImmediately: false,
      campaignMessageKey: 0,
    };
  },
  computed: {
    ...mapState({
      draftCampaign: (state) => state.auth.user.quickCampaignsDraft,
    }),
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),
  },
  watch: {
    'model.messageType': async function (newVal, oldVal) {
      if (oldVal) {
        await this.$refs.campaignMessage.validateMessage();
      }
    },
  },
  created() {
    this.initCampaign();
  },
  beforeDestroy() {
    if (!this.campaignSent) {
      this.model.status = this.$enums.Campaign.Status.DRAFT;
      const payload = this.model.toDraftPayload();
      this.saveDraftQuickCampaign(payload);
    }
  },
  methods: {
    ...mapActions({
      fetchCostQuickCampaign: 'smsCampaign/fetchCostSMSCampaign',
      addSMSQuickCampaign: 'smsCampaign/addSMSQuickCampaign',
      getTestCostSMSCampaign: 'smsCampaign/fetchTestCostSMSCampaign',
      testCampaign: 'smsCampaign/testSMSCampaign',

      saveDraftQuickCampaign: 'user/saveDraftQuickCampaign',
      discardDraftQuickCampaign: 'user/discardDraftQuickCampaign',

      fetchAllContacts: 'contact/fetchAllContacts',
    }),
    async initCampaign() {
      const isDraft = (!this.modelPayload && !!this.draftCampaign)
        || (!!this.modelPayload && !!this.modelPayload.id
          && this.modelPayload.status === this.$enums.Campaign.Status.DRAFT
          && this.operation === this.$enums.Operation.EDIT);

      const finalPayload = !this.modelPayload && !!this.draftCampaign
        ? { ...this.draftCampaign } : { ...this.modelPayload };

      if (this.isCloning || isDraft) {
        const contactsNumbers = finalPayload.quickContacts.filter((quickContact) => {
          try {
            const phoneNumber = parsePhoneNumber(quickContact);

            return phoneNumber && phoneNumber.isValid();
          } catch (e) {
            return false;
          }
        }).map((quickContact) => ({
          name: quickContact,
        }));
        let quickContacts = [...contactsNumbers];

        const contactsIds = finalPayload.quickContacts.filter((quickContact) => /^[0-9a-fA-F]{24}$/.test(quickContact));
        if (contactsIds.length > 0) {
          const contacts = await this.fetchAllContacts({
            filters: {
              _id: {
                filterType: this.$enums.AppFilterType.ENUM,
                type: this.$enums.AppFilter.FilterType.Enum.Type.ONE,
                filter: contactsIds,
              },
            },
          });

          quickContacts = [...quickContacts, ...contacts.data];
        }

        finalPayload.quickContacts = quickContacts;
      }

      this.model = new SmsCampaignConstructor(this.operation, finalPayload, isDraft, true);
      this.focusOnInit = false;
    },
    async onTest(phoneNumber) {
      const campaignInfoIsValid = await this.$refs.campaignInfo.validate();
      const campaignMsgIsValid = await this.$refs.campaignMessage.validateMessage();

      if (!campaignInfoIsValid || !campaignMsgIsValid) {
        return;
      }

      const resp = await this.getTestCostSMSCampaign({
        ...this.model.toTestPayload(),
        sender: phoneNumber,
      });

      if (resp.canPaid) {
        this.confirmTestCampaign({
          campaignType: this.$tc('$General.SMS'),
          senderId: phoneNumber,
          cost: resp.cost,
        });
      } else {
        this.$showConfirmWarningDialog({
          title: 'Test SMS Campaign message',
          text: 'You do not have enough balance to send this test SMS',
          accept: () => {
            this.showCheckout = true;
          },
          acceptText: this.$t('$General.Recharge'),
        });
      }
    },
    changeToTransactional() {
      this.model.messageType = this.$enums.Campaign.MessageType.TRANSACTIONAL;
    },
    async sendCampaign() {
      const campaignInfoIsValid = await this.$refs.campaignInfo.validate();
      const campaignMsgIsValid = await this.$refs.campaignMessage.validateMessage();
      const campaignSettingsAreValid = await this.$refs.campaignSettings.validate();
      const contactsAreValid = await this.$refs.vSelectServerPhoneNumber.validateSelect();

      if (campaignInfoIsValid
        && campaignMsgIsValid
        && campaignSettingsAreValid
        && contactsAreValid
        && this.validateForm) {
        if (this.model.deliveryType === this.$enums.Campaign.DeliveryType.LATER
          && this.campaignWillStartImmediately) {
          this.$showAlertWarningDialog({
            title: this.$t('$ScheduleTimeInPastAlert.Title'),
            text: this.$t('$ScheduleTimeInPastAlert.Text'),
            accept: this.confirmSendQuickCampaign,
          });
        } else {
          await this.confirmSendQuickCampaign();
        }
      }
    },
    async confirmRestartCampaign() {
      this.$showConfirmWarningDialog({
        title: this.$t('$CampaignsModules.ConfirmStartOverCampaignTitle'),
        text: this.$t('$CampaignsModules.ConfirmStartOverCampaignMsg'),
        accept: () => this.restartCampaign(this.model.isDraft),
      });
    },
    async restartCampaign(discardDraft = true) {
      if (discardDraft) {
        await this.discardDraftQuickCampaign();

        this.$showSuccessActionNotification({
          title: this.$t('$CampaignsModules.ConfirmStartOverCampaignNtfTitle'),
          text: this.$t('$CampaignsModules.ConfirmStartOverCampaignNtfMsg'),
        });
      }

      if (this.isCloning) {
        await this.$router.push(`${this.campaignBaseRoute}/quick`);
      } else {
        this.campaignWillStartImmediately = false;
        this.campaignInfoIsValid = false;
        this.campaignMessageIsValid = false;
        this.campaignSettingsIsValid = false;
        this.showCheckout = false;

        this.campaignMessageKey += 1;
        await this.initCampaign();
      }
    },
  },
};
</script>
